<template>
  <div>
    <div class="headTitle">
      <head-layout :is-show-tip="isShowTip" v-if="title" :head-title="title" />
      <div class="flex-container ">
        <el-button
          size="small"
          @click="save"
          v-if="updateName === 'updateName'"
          style="margin-right: 5px"
          >保存</el-button
        >
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :before-upload="beforeSuccess"
          :on-success="uploadSuccess"
          :on-preview="handlePreview"
          :on-remove="handleRemoveFile"
          :http-request="handleHttpRequest"
          :on-exceed="handleExceed"
          multiple
          v-if="!disabled"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
    </div>
    <grid-layout
      ref="gridLayOut"
      class="fileTable"
      :class="tableData.length == 0 ? 'noFile' : ''"
      head-title="文件上传"
      :table-options="option"
      :table-data="tableData"
      :table-loading="loading"
      :data-total="page.total"
      :grid-row-btn="gridRowBtn"
      @grid-row-detail-click="rowView"
      @row-download="rowDownload"
      @row-del="rowDel"
      @row-pause="rowPause"
      :page="page"
    >
    </grid-layout>
    <fileView ref="fileView" title="预览"></fileView>
  </div>
</template>

<script>
// import mammoth from "mammoth";
import GridLayout from "@/views/components/layout/grid-layout";
import md5 from "@/util/md5";
import { mapGetters } from "vuex";
import Queue from "promise-queue-plus";
import ServerNameEnum from "@/util/ServerNameEnum";
import { taskInfo, initTask, preSignUrl, merge } from "@/api/resource/attach";
const Base64 = require("js-base64").Base64;
import website from "@/config/website";
export default {
  name: "index",
  components: { GridLayout },
  props: {
    filterMethods: Function,
    accept: {
      type: String,
      default: "",
    },
    fileSize: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: "文件上传",
    },
    updateName: {
      type: String,
      default: "no",
    },
    isShowTip: {
      type: Boolean,
      default: false,
    },
    fileOptions: {
      type: Object,
      default: () => ({
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        linklabel: "",
        column: [
          {
            label: "文件名称",
            prop: "fileName",
            align: "left",
            overHidden: true,
          },
          {
            label: "上传进度",
            prop: "schedule",
            align: "left",
            overHidden: true,
          },
        ],
      }),
    },
    tableFiles: {
      type: String,
      default: "",
    },
    uploadLimit: {
      type: Number,
      default: 9,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    gridRowBtn: {
      type: Array,
      default: () => [
        {
          label: "下载",
          emit: "row-download",
        },
        // {
        //   label: '暂停',
        //   emit: "row-pause",
        // },
        {
          label: "删除",
          emit: "row-del",
        },
      ],
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      fileList: [],
      tableData: [],
      fileName: "",
      fileType: "",
      vHtml: "", // word预览
      dialogImageUrl: "",
      dialogVisible: false,
      upload_btn: false,
      noneUploadImg: false,
      option: {
        menu: !this.disabled ? true : false,
        height: this.fileOptions.height ? this.fileOptions.height : "auto",
        maxHeight: this.fileOptions.maxHeight ? this.fileOptions.maxHeight : "",
        selection:
          this.fileOptions.selection != undefined
            ? this.fileOptions.selection
            : false,
        linklabel: this.fileOptions.linklabel ? this.fileOptions.linklabel : "",
        index:
          this.fileOptions.index != undefined ? this.fileOptions.index : true,
        indexLabel: this.fileOptions.indexLabel
          ? this.fileOptions.indexLabel
          : this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: this.fileOptions.menuWidth
          ? this.fileOptions.menuWidth
          : 200,
        column:
          this.fileOptions.column && this.fileOptions.column.length != 0
            ? this.fileOptions.column
            : [],
      },
      data: [],
      loading: false,
      page: {
        total: 0,
      },
      fileUploadChunkQueue: {},
      fileDownloadQueue: {},
      // resTtableData: [], //责任书暂存数据
    };
  },
  watch: {
    ids() {
      let ids = this.tableData.map((item) => item.id).join(",");
      return ids;
    },
    tableData() {
      this.$nextTick(() => {
        this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        this.$refs.gridLayOut.$refs.grid.refreshTable();
      });
    },
  },
  methods: {
    // filterMethods(arr){
    //     arr.filter((item) => {
    //       if (item.resType == "三级教育卡") {
    //         return item;
    //       }
    //     });
    //     arr.filter((item) => {
    //       if (item.resType == "安全责任书" || item.resType == "环保责任书") {
    //         return item;
    //       }
    //     });
    // },
    initTaleData(data) {
      if (data) {
        let tableData1 = JSON.parse(data); //全部
        this.tableData = this.filterMethods ? this.filterMethods(tableData1) : tableData1;
      } else {
        this.tableData = [];
      }
      this.$nextTick(() => {
        this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        this.$refs.gridLayOut.$refs.grid.refreshTable();
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res, "handleAvatarSuccess");
      this.imageUrl = res.path;
    },
    // 删除图片，判断数量，是否显示icon
    handleDeleteImgRemove() {
      this.noneUploadImg = true;
    },
    // 查看预览 下载
    rowView(row) {
      this.$refs.fileView.showFile(row.path)
    },
    rowDownload(row) {
      let fileId = row.id;
      if (!fileId) {
        this.$message.error("文件未上传成功");
        return;
      }
      if (row.path) {
        let that = this;
        let fileName = row.name;
        fetch(row.path)
          .then((res) => res.blob())
          .then((blob) => {
            let objectUrl = URL.createObjectURL(blob); // 创建 url 对象
            that.aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
          })
          .catch((err) => {
            // console.log("Request Failed", err);
          });
      }
    },
    // 下载
    aTagDownload(url, name) {
      const fileUrl = url; // 文件的URL地址
      const link = document.createElement("a"); // 创建一个链接元素
      link.href = fileUrl; //重点（如测试发现下载文件不存在/找不到，检查路径）
      link.download = name; // 设置下载文件文件名
      link.style.display = "none";
      document.body.appendChild(link); // 将链接元素添加到页面中
      link.click(); // 触发链接的点击事件以触发图像下载
      document.body.removeChild(link); // 在完成下载后，从页面中移除链接元素
    },
    rowDel(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const queueObject = this.fileUploadChunkQueue[row.uid];
          if (queueObject) {
            queueObject.stop();
            this.tableData = this.tableData.filter(
              (item) => item.uid != row.uid
            );
          } else {
            this.tableData = this.tableData.filter(
              (item) => item.uid != row.uid
            );
          }
          this.$emit("fileIds", this.ids);
          this.$emit("getTableData", this.tableData);
          this.$emit("deleteId", row.id);
        })
        .catch(() => {});
    },
    rowPause(row) {
      const queueObject = this.fileUploadChunkQueue[row.uid];
      if (queueObject) {
        queueObject.stop();
        // this.fileUploadChunkQueue[task.uid] = undefined
      }
    },
    uploadSuccess(res) {
      if (!res) return false;
      this.tableData.find((item) => item.uid == res.uid).id = res.id;
      // let ids = this.tableData.map(item => item.id).join(',')
      this.$emit("fileIds", this.ids);
      // var mergedArray = this.tableData.concat(this.resTtableData); //合并教育卡与责任书
      // this.$emit("getTableData", mergedArray); //传给抽屉展示
      // console.log(mergedArray, "uploadSuccess");
      this.$emit("getTableData", this.tableData);
      // console.log(this.tableData,"uploadSuccess")
    },
    // 超出上传文件限制
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.uploadLimit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    save() {
      this.$emit("saveTableData", this.tableData);
    },
    // 转化文件大小
    changeFileSize(val) {
      if (val == "0") return "0B";
      var k = 1024;
      var sizes = ["B", "KB", "MB", "GB", "TB"];
      console.log("Math.log(val)", Math.log(val));
      console.log("Math.log(k)", Math.log(k));
      let i = Math.floor(Math.log(val) / Math.log(k)); //得出该数字的单位应该是kB?MB
      return (val / Math.pow(k, i)).toPrecision(3) + "" + sizes[i];
    },
    // 转化时间
    timestampToTime(timestamp) {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      let resultTime =
        y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      return resultTime;
    },
    beforeSuccess(res) {
      if (this.tableData.length >= this.uploadLimit) {
        return this.$message.warning(
          `最大文件限制选择 ${this.uploadLimit} 个文件`
        );
      }
      let fileName = res.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length).toLowerCase();

      let arr = Array.from(this.accept.split(","));
      if (this.accept && !arr.includes(lastName)) {
        this.$message.warning("请选择" + this.accept + "类型文件");
        return false;
      }
      const isSize = res.size / 1024 / 1024; // 文件大小
      if (this.fileSize && isSize > this.fileSize) {
        this.$message.warning(`上传文件大小不能超过${this.fileSize}MB!`);
        return false;
      }
      res.extension = lastName; // 上传格式
      res.fileSize = this.changeFileSize(res.size); // 上传文件大小
      res.createTime = this.timestampToTime(); // 上传时间
      res.createUserName = this.userInfo.nick_name || this.userInfo.name;
      res.createUser = this.userInfo.user_id;
      res.fileName = res.name;
      this.tableData.push(res);
      // console.log(this.tableData,"beforeSuccess")
    },
    // 上传失败删除tableData已经push的数据
    uploadError(row) {
      this.rowDel(row);
    },
    handlePreview(task) {
      const queueObject = this.fileUploadChunkQueue[task.uid];
      if (queueObject) {
        queueObject.stop();
        // this.fileUploadChunkQueue[task.uid] = undefined
      }
      if (queueObject.getRunCount() == 0) {
        queueObject.start();
      }
      //文件下载
      /*let fileId = task.response
      axios({
        url: ServerNameEnum.SERVER_RESOURCE_+'/file/download/'+fileId,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'filename.ext');
        document.body.appendChild(link);
        link.click();
        this.downloading = false;
        this.progress = 0;
      });*/
    },
    downloadBinaryFile(
      binFile,
      fileName,
      blobType = "application/octet-stream"
    ) {
      // 处理二进制数据并创建 Blob 对象
      const blobObj = new Blob([binFile], { type: blobType });
      // 创建一个链接并设置下载属性
      const downloadLink = document.createElement("a");
      let url = window.URL || window.webkitURL || window.moxURL; // 兼容不同浏览器的 URL 对象
      url = url.createObjectURL(blobObj);
      downloadLink.href = url;
      downloadLink.download = fileName; // 设置下载的文件名
      // 将链接添加到 DOM 中，模拟点击
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // 移除创建的链接和释放 URL 对象
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    },
    async getTaskInfo(file) {
      if (file.size == 0) return false;
      let task;
      const identifier = await md5(file);
      // console.log('identifier--md5',identifier)
      let res = await taskInfo(identifier);
      if (res.data.code === 200) {
        task = res.data.data;
        if (Object.keys(task).length < 1) {
          let initTaskData = {
            identifier: identifier,
            fileName: file.name,
            totalSize: file.size,
            chunkSize: 5 * 1024 * 1024,
          };
          let initTaskRes = await initTask(initTaskData);
          if (initTaskRes.data.code == 200) {
            task = initTaskRes.data.data;
          } else {
            this.$message.error({
              title: "文件上传错误",
              message: initTaskRes.data.msg,
            });
          }
        }
      } else {
        this.$message.error({
          title: "文件上传错误",
          message: res.data.msg,
        });
      }
      return task;
    },
    async handleHttpRequest(options) {
      const file = options.file;
      const task = await this.getTaskInfo(options.file);
      if (task) {
        const { finished, path, taskRecord } = task;
        const { identifier, id } = taskRecord;
        if (finished) {
          this.tableData.find((item) => item.uid == file.uid).schedule = "100%";
          this.tableData.find((item) => item.uid == file.uid).path = path;
          this.schedule = 100;
          this.$forceUpdate();
          return { id: id, uid: file.uid, path: path };
        } else {
          const errorList = await this.handleUpload(file, taskRecord, options);
          if (errorList.length > 0) {
            this.$message.error({
              title: "文件上传错误",
              message: "部分分片上传失败，文件损坏，请尝试重新上传文件",
            });
            this.uploadError(file);
            return;
          }
          const mergeRes = await merge(identifier);
          let code = mergeRes.data.code;
          let msg = mergeRes.data.msg;
          if (code === 200) {
            // 这个合并后的path需要重新确认下 -- 待确定
            this.tableData.find((item) => item.uid == file.uid).schedule =
              "100%";
            this.tableData.find((item) => item.uid == file.uid).path = path;
            return { id: id, uid: file.uid, path: path };
          } else {
            this.$message.error({
              title: "文件上传错误",
              message: msg,
            });
            this.uploadError(file);
          }
        }
      } else {
        this.$message.warning({
          message: "不允许上传空文件",
        });
        console.log(this.tableData);
        this.tableData = this.tableData.filter(
          (e) => e.uid != options.file.uid
        );
      }
    },
    handleRemoveFile(uploadFile, uploadFiles) {
      const queueObject = this.fileUploadChunkQueue[uploadFile.uid];
      if (queueObject) {
        queueObject.stop();
        this.fileUploadChunkQueue[uploadFile.uid] = undefined;
      }
    },
    async handleUpload(file, taskRecord, options) {
      let lastUploadedSize = 0; // 上次断点续传时上传的总大小
      let uploadedSize = 0; // 已上传的大小
      const totalSize = file.size || 0; // 文件总大小
      let startMs = new Date().getTime(); // 开始上传的时间
      const { exitPartList, chunkSize, chunkNum, identifier } = taskRecord;

      // 获取从开始上传到现在的平均速度（byte/s）
      const getSpeed = () => {
        // 已上传的总大小 - 上次上传的总大小（断点续传）= 本次上传的总大小（byte）
        const intervalSize = uploadedSize - lastUploadedSize;
        const nowMs = new Date().getTime();
        // 时间间隔（s）
        const intervalTime = (nowMs - startMs) / 1000;
        return intervalSize / intervalTime;
      };

      const uploadNext = async (partNumber) => {
        const start = new Number(chunkSize) * (partNumber - 1);
        const end = start + new Number(chunkSize);
        const blob = file.slice(start, end);
        const res = await preSignUrl(identifier, partNumber);
        let code = res.data.code;
        let data = res.data.data;
        if (code === 200 && data) {
          await axios.request({
            url: data,
            method: "PUT",
            data: blob,
            headers: { "Content-Type": "application/octet-stream" },
          });
          return Promise.resolve({
            partNumber: partNumber,
            uploadedSize: blob.size,
          });
        }
        return Promise.reject(`分片${partNumber}， 获取上传地址失败`);
      };

      /**
       * 更新上传进度
       * @param increment 为已上传的进度增加的字节量
       */
      const updateProcess = (increment) => {
        increment = new Number(increment);
        const { onProgress } = options;
        let factor = 1000; // 每次增加1000 byte
        let from = 0;
        // 通过循环一点一点的增加进度
        while (from <= increment) {
          from += factor;
          uploadedSize += factor;
          const percent = Math.round((uploadedSize / totalSize) * 100).toFixed(
            2
          );
          onProgress({ percent: percent });
        }

        const speed = getSpeed();
        const remainingTime =
          speed != 0
            ? Math.ceil((totalSize - uploadedSize) / speed) + "s"
            : "未知";
        this.tableData.find((item) => item.uid == file.uid).schedule =
          parseInt((uploadedSize / totalSize) * 100) > 100
            ? 100 + "%"
            : parseInt((uploadedSize / totalSize) * 100) + "%";
        this.$forceUpdate();
        console.log(
          "剩余大小：",
          (totalSize - uploadedSize) / 1024 / 1024,
          "mb"
        );
        console.log("进度：", parseInt((uploadedSize / totalSize) * 100), "%");
        console.log("当前速度：", (speed / 1024 / 1024).toFixed(2), "mbps");
        console.log("预计完成：", remainingTime);
      };

      return new Promise((resolve) => {
        const failArr = [];
        const queue = Queue(5, {
          retry: 3, //Number of retries
          retryIsJump: false, //retry now?
          workReject: function (reason, queue) {
            failArr.push(reason);
          },
          queueEnd: function (queue) {
            resolve(failArr);
          },
        });
        this.fileUploadChunkQueue[file.uid] = queue;
        for (let partNumber = 1; partNumber <= chunkNum; partNumber++) {
          const exitPart = (exitPartList || []).find(
            (exitPart) => exitPart.partNumber == partNumber
          );
          if (exitPart) {
            // 分片已上传完成，累计到上传完成的总额中,同时记录一下上次断点上传的大小，用于计算上传速度
            lastUploadedSize += new Number(exitPart.size);
            updateProcess(exitPart.size);
          } else {
            queue.push(() =>
              uploadNext(partNumber).then((res) => {
                // 单片文件上传完成再更新上传进度
                updateProcess(res.uploadedSize);
              })
            );
          }
        }
        if (queue.getLength() == 0) {
          // 所有分片都上传完，但未合并，直接return出去，进行合并操作
          resolve(failArr);
          return;
        }
        queue.start();
      });
    },
  },
};
</script>

<style scoped lang="less">
.headTitle {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .upload-demo {
    padding-right: 10px;
  }
}
::v-deep .el-card .is-never-shadow {
  height: 300px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
::v-deep .fileTable .avue-crud .el-table {
  //height: auto !important;
  //max-height: auto !important;
}
::v-deep .filePreviewDialog .el-dialog__body {
  height: 90% !important;
}
::v-deep .el-dialog__body {
  height: 100%;
}

::v-deep .avue-crud .el-table {
  height: auto !important;
  max-height: calc(100vh - 274px) !important;
}

::v-deep .avueCrud .el-table {
  height: auto !important;
  max-height: calc(100vh - 274px) !important;
}

::v-deep .avueCrudNoTag .el-table {
  height: auto !important;
  max-height: calc(100vh - 234px) !important;
}

::v-deep .noFile .avue-crud .el-table {
  height: 100px !important;
}

::v-deep .noFile .avueCrud .el-table {
  height: 100px !important;
}

::v-deep .noFile .avueCrudNoTag .el-table {
  height: 100px !important;
}
</style>
